.query-page-admin {
  padding: 20px;
  background-color: #f0f0f0;
  min-height: 100vh;
}

.query-page-admin h2 {
  color: #276D8B;
  margin-bottom: 20px;
}

.query-controls-admin {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.query-results-admin {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.query-results-admin h3 {
  color: #276D8B;
  margin-bottom: 15px;
}

.results-list-admin {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.result-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.result-item h4 {
  color: #276D8B;
  margin-bottom: 10px;
}

.result-item p {
  margin: 5px 0;
}

.result-item strong {
  color: #333;
}

.result-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.open-profile-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #276D8B;
  transition: color 0.3s ease;
}

.open-profile-icon:hover {
  color: #1c4d62;
}

.open-profile-icon span {
  margin-left: 5px;
}

@media (max-width: 768px) {
  .query-controls-admin {
    flex-direction: column;
  }
}

.results-list-admin.compact-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}

.result-item-compact {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  position: relative;
}

.result-item-compact {
  cursor: pointer;
  transition: all 0.3s ease;
}

.result-item-compact:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.result-item-compact .open-profile-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.filter-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.filter-checkbox input {
  margin-right: 10px;
}

.compact-content {
  margin-right: 30px;
}

.expanded-content {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
}