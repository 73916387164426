@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.example-manager {
  font-family: 'Inter', sans-serif;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 20px;
}

.examples-list {
  flex: 2;
}

.example-form-container {
  flex: 1;
}

.example-manager h1, .example-form-container h2 {
  color: #276D8B;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.example-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.example-form select,
.example-form input,
.example-form textarea,
.example-form button {
  width: 100%;
  padding: 12px;
  border: 1px solid #276D8B;
  border-radius: 4px;
  font-size: 14px;
  color: #276D8B;
  box-sizing: border-box;
}

.example-form select:focus,
.example-form input:focus,
.example-form textarea:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(39, 109, 139, 0.2);
}

.example-form button {
  background-color: #276D8B;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  border: none;
  font-weight: 600;
}

.example-form button:hover {
  background-color: #1c5c7a;
}

.example-form button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.user-search {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.examples-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.examples-table th,
.examples-table td {
  padding: 12px;
  text-align: left;
}

.examples-table th {
  background-color: #276D8B;
  color: white;
  font-weight: 600;
}

.examples-table tr {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.loading {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
}

@media (max-width: 1024px) {
  .example-manager {
    flex-direction: column;
  }

  .examples-list,
  .example-form-container {
    width: 100%;
  }

  .example-form select,
  .example-form input,
  .example-form textarea,
  .example-form button,
  .user-search input,
  .user-search select {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .examples-table {
    font-size: 14px;
  }

  .examples-table th,
  .examples-table td {
    padding: 8px;
  }
}

.status-skew {
    display: inline-block;
    padding: 6px 10px;
    border-radius: 4px;
    font-weight: 600;
    text-transform: capitalize;
  }
  
  .status-skew.status-accepted { background-color: #4CAF50; color: white; }
  .status-skew.status-rejected { background-color: #F44336; color: white; }
  .status-skew.status-pending { background-color: #2196F3; color: white; }

  .delete-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #ff4d4d;
    margin-left: 10px;
  }
  
  .delete-button:hover {
    color: #ff0000;
  }