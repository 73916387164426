.formatting-docs {
    padding: 20px;
    background-color: #ffffff;
    color: #333;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .formatting-docs h1, .formatting-docs h2 {
    color: #276d8b;
  }
  
  .formatting-docs pre {
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 5px;
    overflow: auto;
  }
  