:root {
    --primary-color: #276D8B;
    --background-color: #E4E4E4;
    --sidebar-color: #f0f0f0;
    --text-color: #333;
    --border-color: #e0e0e0;
    --hover-color: #d32f2f;
  }
  
  .user-search-admin {
    padding: 20px;
    background-color: var(--background-color);
    min-height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: var(--text-color);
  }
  
  .user-search-admin h2 {
    color: var(--primary-color);
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .search-container {
    position: relative;
    margin-bottom: 20px;
  }
  
  .search-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: #ffffff;
  }
  
  .suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #ffffff;
    border: 1px solid var(--border-color);
    border-top: none;
    border-radius: 0 0 4px 4px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .suggestions-list li {
    padding: 10px;
    cursor: pointer;
  }
  
  .suggestions-list li:hover {
    background-color: var(--sidebar-color);
  }
  
  .user-details {
    background-color: #ffffff;
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
  }
  
  .user-profile h2 {
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .user-profile p {
    margin: 10px 0;
    color: var(--text-color);
  }
  
  .contribution-history {
    margin-top: 30px;
    max-width: 900px;

  }
  
  .contribution-history h3 {
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .contribution-graph {
    display: flex;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    overflow: hidden;
    padding-top: 20px;
  }
  
  .graph-labels {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 5px;
    background-color: var(--sidebar-color);
    border-right: 1px solid var(--border-color);
  }
  
  .day-label {
    height: 15px;
    font-size: 9px;
    color: var(--text-color);
    text-align: right;
    line-height: 15px;
  }
  
  .graph-body {
    display: flex;
    flex-grow: 1;
    padding: 5px;
  }
  
  .graph-week {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  
  .contribution-day {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin: 1px;
    position: relative;
  }
  
  .contribution-day::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primary-color);
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
    z-index: 1000;
  }
  
  .contribution-day:hover::after {
    opacity: 1;
    visibility: visible;
  }
  
  .month-labels {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    font-size: 10px;
    color: var(--text-color);
  }
  
  .contribution-summary {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;
    font-size: 11px;
    color: var(--text-color);
  }
  
  .contribution-scale {
    display: flex;
    list-style-type: none;
    margin: 0 8px;
    padding: 0;
  }
  
  .contribution-scale li {
    width: 10px;
    height: 10px;
    margin-right: 2px;
  }
  
  .level-0 { background-color: #ebedf0; }
  .level-1 { background-color: #c6e48b; }
  .level-2 { background-color: #7bc96f; }
  .level-3 { background-color: #239a3b; }
  .level-4 { background-color: #196127; }
  
  .activity-accordion {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .activity-accordion h3 {
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .activity-item {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    margin-bottom: 10px;
    overflow: hidden;
  }
  
  .activity-item summary {
    padding: 15px;
    cursor: pointer;
    background-color: var(--sidebar-color);
    transition: background-color 0.3s ease;
  }
  
  .activity-item summary:hover {
    background-color: #e1e1e1;
  }
  
  .activity-item[open] summary {
    background-color: #0b3a4e;
    color: #ffffff !important;
  }
  
  .activity-summary-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .activity-date {
    font-weight: bold;
  }
  
  .activity-type {
    font-style: italic;
  }
  
  .activity-snippet {
    margin-top: 10px;
    font-size: 0.9em;
    color: #666;
    line-height: 1.4;
  }
  
  .activity-details {
    padding: 15px;
    background-color: #ffffff;
  }
  
  .activity-details p {
    margin: 10px 0;
  }
  
  .activity-details strong {
    color: var(--primary-color);
  }
  
  /* Tooltip arrow */
  .contribution-day::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--primary-color) transparent;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
  }
  
  .contribution-day:hover::before {
    opacity: 1;
    visibility: visible;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .contribution-history,
    .activity-accordion {
      max-width: 100%;
      overflow-x: auto;
    }
  
    .contribution-graph {
      min-width: 700px;
    }
  
    .contribution-day {
      width: 8px;
      height: 8px;
    }
  
    .day-label {
      font-size: 8px;
    }
  
    .month-labels {
      font-size: 9px;
    }
  
    .activity-summary-row {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .activity-type {
      margin-top: 5px;
    }
  
    .activity-snippet {
      margin-top: 10px;
    }
  }
  
  /* Accessibility improvements */
  .contribution-day:focus,
  .activity-item summary:focus {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
  }
  
  /* Print styles */
  @media print {
    .user-search-admin {
      background-color: #ffffff;
    }
  
    .contribution-graph,
    .activity-accordion {
      page-break-inside: avoid;
    }
  
    .contribution-day::after,
    .contribution-day::before {
      display: none;
    }
  
    .activity-item summary {
      background-color: var(--sidebar-color) !important;
      color: var(--text-color) !important;
    }
  
    .activity-item[open] summary {
      border-bottom: 1px solid var(--border-color);
    }
  }