/* Admin.css */

@import url('https://fonts.googleapis.com/css2?family=Faustina:ital,wght@0,300..800;1,300..800&display=swap');
.layout {
    display: flex;
    min-height: 100vh;
    background-color: #e4e4e4;
  }
  
  .sidebar {
    max-width: 250px;
    min-width: 250px;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: sticky;
    top: 0;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .sidebar-content {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    color: #276d8b;
    margin-bottom: 20px;
    font-family: "Faustina";
}
  
  .nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  .nav-item {
    padding: 10px 0;
    cursor: pointer;
  }
  
  .nav-item a {
    text-decoration: none;
    color: inherit;
    display: block;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .nav-item:hover a {
    color: #276d8b;
  }
  
  .nav-item.active a {
    background-color: #276d8b;
    color: white;
  }
  
  .section-title {
    font-weight: bold;
    color: #888;
    margin-top: 15px;
  }
  
  .separator {
    height: 2px;
    background-color: #e0e0e0;
    margin: 10px 0;
  }
  
  .user-info {
    margin-top: 20px;
    padding: 10px 20px;
  }
  
  .user-name {
    color: #276d8b;
    font-weight: 700;
    font-size: 18px;
  }
  
  .logout-btn {
    background-color: #276d8b;
    color: white;
    border: none;
    padding: 10px 15px;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
  }
  
  .logout-btn:hover {
    background-color: #d32f2f;
  }
  
  .main-content {
    flex-grow: 1;
    position: relative;
    padding: 20px;
  }
  
  .menu-toggle {
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .page-title {
    color: #276d8b;
    margin-bottom: 20px;
  }
  
  .welcome-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 30px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .sidebar {
      position: fixed;
      left: -250px;
      top: 0;
      bottom: 0;
      transition: left 0.3s ease;
      z-index: 1000;
    }
  
    .sidebar.open {
      left: 0;
    }
  
    .menu-toggle {
      display: block;
    }
  
    .main-content {
      padding-top: 50px;
    }
  
    .menu-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999;
    }
  }
  
  /* Accessibility improvements */
  .nav-item a:focus,
  .logout-btn:focus,
  .menu-toggle:focus {
    outline: 2px solid #276d8b;
    outline-offset: 2px;
  }
  
  /* Admin.css */
  
  /* ... existing styles ... */
  
  .dashboard-content {
    display: flex;
    flex-direction: column;
  }
  
  .dashboard-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .half-column {
    flex: 1;
    min-width: 300px;
    margin-right: 20px;
  }
  
  .half-column:last-child {
    margin-right: 0;
  }
  
  @media (max-width: 768px) {
    .dashboard-row {
      flex-direction: column;
    }
  
    .half-column {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  
  @media (min-width: 769px) {
    .half-column {
      max-width: calc(50% - 10px);
    }
  }
  
  