.password-prompt-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .password-prompt-container {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .password-prompt-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .password-prompt-input {
    width: 95%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
  }
  
  .password-prompt-error {
    color: #d32f2f;
    margin-bottom: 1rem;
  }
  
  .password-prompt-button {
    width: 100%;
    padding: 0.5rem;
    background-color: #276d8b;
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .password-prompt-button:hover {
    background-color: #1c5a75;
  }