.user-leaderboard {
  padding: 20px;
  background-color: #E4E4E4;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.user-leaderboard h2 {
  color: #276D8B;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.leaderboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.user-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.user-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.user-rank {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #276D8B;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
}

.user-info h3 {
  color: #276D8B;
  margin-bottom: 10px;
  font-size: 18px;
}

.user-email,
.user-uuid,
.user-score,
.user-entries,
.user-subentries,
.user-last-active {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}

.user-email {
  font-style: italic;
}

.user-uuid {
  font-size: 12px;
  color: #666;
}

.user-score {
  font-weight: bold;
  color: #276D8B;
}

.user-subentries {
  font-size: 12px;
  color: #666;
}

.user-last-active {
  font-size: 12px;
  color: #888;
  margin-top: 10px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
  color: #276D8B;
}

@media (max-width: 768px) {
  .leaderboard-grid {
    grid-template-columns: 1fr;
  }
}