.signup-log {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.signup-log h3-log {
  color: #276d8b;
  margin-bottom: 15px;
  font-size: 20px;
}

.activity-list-log {
  list-style-type: none;
  padding: 0;
}

.activity-item-log {
  display: flex;
  justify-content: space-between;
  background-color: #276d8b;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.activity-item-log:hover {
  background-color: #1c4d62;
}

.user-info-log {
  display: flex;
  flex-direction: column;
}

.user-name-log {
  font-weight: bold;
  color: #ffffff;
}

.user-email-log {
  color: #ffffff;
  font-size: 12px;
}

.activity-time-log {
  font-size: 12px;
  text-align: right;
  color: #ffffff;
}

.activity-row-log {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .signup-log {
    max-width: 100%;
    margin-bottom: 20px;
  }
  
  .activity-item-log {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .activity-time-log {
    margin-top: 10px;
    text-align: left;
  }
}