/* ContributionHistory.css */

.contribution-history {
    margin-top: 30px;
    max-width: 675px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .contribution-history h3 {
    color: #276d8b;
    margin-bottom: 15px;
    font-size: 20px;
  }
  
  .contribution-graph {
    display: flex;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    overflow: hidden;
  }
  
  .graph-labels {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 5px;
    background-color: #f0f0f0;
    border-right: 1px solid #e0e0e0;
  }
  
  .day-label {
    height: 15px;
    font-size: 9px;
    color: #333;
    text-align: right;
    line-height: 15px;
  }
  
  .graph-body {
    margin-top: 10px;
    display: flex;
    flex-grow: 1;
    padding: 5px;
  }
  
  .graph-week {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  
  .contribution-day {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin: 1px;
    position: relative;
  }
  
  .contribution-day.empty {
    background-color: transparent;
  }
  
  .contribution-day::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #276d8b;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
    z-index: 1000;
  }
  
  .contribution-day:hover::after {
    opacity: 1;
    visibility: visible;
  }
  
  .month-labels {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    font-size: 10px;
    color: #333;
  }
  
  .month-label {
    flex: 1;
    text-align: center;
  }
  
  .contribution-summary {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;
    font-size: 11px;
    color: #333;
  }
  
  .contribution-scale {
    display: flex;
    list-style-type: none;
    margin: 0 8px;
    padding: 0;
  }
  
  .contribution-scale li {
    width: 10px;
    height: 10px;
    margin-right: 2px;
  }
  
  .level-0 { background-color: #ebedf0; }
  .level-1 { background-color: #c6e48b; }
  .level-2 { background-color: #7bc96f; }
  .level-3 { background-color: #239a3b; }
  .level-4 { background-color: #196127; }
  
  .today-count {
    margin-top: 15px;
    font-size: 14px;
    color: #276d8b;
    font-weight: bold;
  }
  
  /* Tooltip arrow */
  .contribution-day::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #276d8b transparent;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
  }
  
  .contribution-day:hover::before {
    opacity: 1;
    visibility: visible;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .contribution-history {
      max-width: 100%;
      overflow-x: auto;
    }
  
    .contribution-graph {
      min-width: 700px;
    }
  
    .contribution-day {
      width: 8px;
      height: 8px;
    }
  
    .day-label {
      font-size: 8px;
    }
  
    .month-labels {
      font-size: 9px;
    }
  }
  
  /* Accessibility improvements */
  .contribution-day:focus {
    outline: 2px solid #276d8b;
    outline-offset: 2px;
  }
  