@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.submissions-manager {
  font-family: 'Inter', sans-serif;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 20px;
}

.submissions-list {
  flex: 2;
}

.submission-form-container {
  flex: 1;
}

.submissions-manager h1, .submission-form-container h2 {
  color: #276D8B;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.submission-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin-top: 80px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.submission-form select,
.submission-form input,
.submission-form button {
  width: 100%;
  padding: 12px;
  border: 1px solid #276D8B;
  border-radius: 4px;
  font-size: 14px;
  color: #276D8B;
  box-sizing: border-box;
}

.submission-form select:focus,
.submission-form input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(39, 109, 139, 0.2);
}

.submission-form button {
  background-color: #276D8B;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  border: none;
  font-weight: 600;
}

.submission-form button:hover {
  background-color: #1c5c7a;
}

.cancel-button {
  background-color: #f44336 !important;
}

.cancel-button:hover {
  background-color: #d32f2f !important;
}

.user-search {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.user-search input,
.user-search select,
.submission-form input[type="datetime-local"] {
  width: 100%;
}

.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filters select {
  padding: 8px;
  border: 1px solid #276D8B;
  border-radius: 4px;
  font-size: 14px;
  color: #276D8B;
}

.submissions-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.submissions-table th,
.submissions-table td {
  padding: 12px;
  text-align: left;
}

.submissions-table th {
  background-color: #276D8B;
  color: white;
  font-weight: 600;
}

.submissions-table tr {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.submissions-table tr.clickable-row {
  cursor: pointer;
  transition: background-color 0.3s;
}

.submissions-table tr.clickable-row:hover {
  background-color: #f0f0f0;
}

.status-skew {
  display: inline-block;
  padding: 6px 10px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: capitalize;
}

.status-skew.status-submitted { background-color: #FFA500; color: white; }
.status-skew.status-unsubmitted { background-color: #7070708a; color: white; }
.status-skew.status-accepted { background-color: #4CAF50; color: white; }
.status-skew.status-rejected { background-color: #F44336; color: white; }
.status-skew.status-pending { background-color: #2196F3; color: white; }

.loading {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.popup-content h2 {
  margin-top: 0;
  color: #276D8B;
}

.popup-content pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 4px;
}

.popup-content button {
  position: sticky;
  bottom: 20px;
  left: 100%;
  transform: translateX(-100%);
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #276D8B;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
}

.raw-request-button {
  background-color: #276D8B;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
}

.raw-request-button:hover {
  background-color: #1c5c7a;
}

@media (max-width: 1024px) {
  .submissions-manager {
    flex-direction: column;
  }

  .submissions-list,
  .submission-form-container {
    width: 100%;
  }

  .submission-form select,
  .submission-form input,
  .submission-form button,
  .user-search input,
  .user-search select {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .submissions-table {
    font-size: 14px;
  }

  .submissions-table th,
  .submissions-table td {
    padding: 8px;
  }

  .filters {
    flex-direction: column;
  }

  .raw-request-button {
    padding: 6px 10px;
    font-size: 12px;
  }
}
