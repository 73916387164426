.prompt-engineering {
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #333;
}

.prompt-engineering h2 {
  margin-bottom: 20px;
  color: #276d8b;
}

.top-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.selection-area {
  flex-grow: 1;
  margin-right: 20px;
}

.button-group {
  display: flex;
  gap: 10px;
}

.breadcrumb-select {
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  background-color: #276d8b;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
}

.breadcrumb-select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(39, 109, 139, 0.2);
}

.breadcrumb-select option {
  background-color: #fff;
  color: #333;
}

.download-button, .save-prompt-button {
  padding: 12px 20px;
  font-size: 16px;
  background-color: #276d8b;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.save-prompt-button {
  background-color: #4CAF50;
  min-width: 200px;
}

.download-button:hover, .save-prompt-button:hover {
  background-color: #1c4d62;
}

.save-prompt-button:hover {
  background-color: #45a049;
}

.download-button:disabled, .save-prompt-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.control-button {
  padding: 12px 20px;
  font-size: 16px;
  background-color: #276d8b;
  border: 1px solid #000000;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.control-button:hover {
  background-color: #1c4d62;
}

.control-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.prompt-builder-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.left-panel {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.available-parts-container {
  background-color: #e0e0e0;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.canvas-container {
  width: 75%;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.available-parts-container h3,
.canvas-container h3 {
  margin-bottom: 15px;
  color: #276d8b;
}

.available-parts {
  min-height: 200px;
}

.prompt-canvas {
  min-height: 400px;
  height: 100%;
  background-color: #ffffff;
  background-image: radial-gradient(#276d8b 1px, transparent 1px);
  background-size: 20px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.prompt-part {
  background-color: #276d8b;
  color: #fff;
  border: 1px solid #1c4d62;
  padding: 15px;
  margin-bottom: 10px;
  cursor: move;
  border-radius: 4px;
  font-size: 16px;
  margin: 20px;
  display: flex;
  align-items: center;
}

.part-icon {
  margin-right: 10px;
}

.priority-icon {
  margin-left: auto;
  color: #ff4136;
}

.bin {
  margin-top: 20px;
  background-color: #f8d7da;
  border: 2px dashed #dc3545;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  color: #dc3545;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bin p {
  margin-top: 10px;
}

.textarea-container {
  display: flex;
  flex-direction: column;
}

.textarea-buttons {
  display: flex;
  gap: 10px;
}

.prompt-preview {
  height: 200px;
  padding: 10px;
  font-family: monospace;
  font-size: 14px;
  resize: vertical;
  background-color: #fff;
  color: #333;
  border: 1px solid #276d8b;
}

.prompt-part.selected {
  background-color: #0c465f;
  border: 2px solid #969696;
}

.prompt-part.priority {
  border: 2px solid #ff4136;
}

@media (max-width: 768px) {
  .top-controls {
    flex-direction: column;
    align-items: stretch;
  }

  .selection-area {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .button-group {
    flex-direction: column;
  }

  .breadcrumb-select {
    width: 100%;
    max-width: none;
  }

  .download-button,
  .save-prompt-button {
    width: 100%;
  }

  .prompt-builder-container {
    flex-direction: column;
  }

  .left-panel,
  .canvas-container {
    width: 100%;
  }

  .available-parts-container {
    margin-bottom: 20px;
  }

  .bin {
    margin-bottom: 20px;
  }

  .textarea-buttons {
    flex-direction: column;
  }

  .control-button {
    width: 100%;
  }
}

.formatting-docs-button {
  padding: 12px 20px;
  font-size: 16px;
  background-color: #276d8b;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 10px;
}

.formatting-docs-button:hover {
  background-color: #1c4d62;
}

.override-prompt {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  color: #856404;
}

.override-prompt p {
  margin-bottom: 10px;
}

.override-prompt ul {
  margin-bottom: 10px;
  list-style-type: disc;
  padding-left: 20px;
}

.override-prompt .control-button {
  margin-right: 10px;
}
