/* PromptList.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.prompt-list-container {
  font-family: 'Inter', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.header-left {
  flex: 1;
}

.header-right {
  flex: 1;
  margin-left: 20px;
}

.prompt-list-title {
  color: #276D8B;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.firm-select-container {
  margin-bottom: 10px;
}

.firm-select-container label {
  margin-right: 10px;
  font-weight: 600;
  color: #276D8B;
}

.firm-select-container select {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #276D8B;
  border-radius: 4px;
  background-color: white;
  color: #276D8B;
  cursor: pointer;
}

.firm-select-container select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(39, 109, 139, 0.2);
}

.summary-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.summary-name {
  font-weight: 600;
}

.summary-status {
  display: flex;
  gap: 10px;
}

.summary-status .present {
  color: #4caf50;
}

.summary-status .null {
  color: #f44336;
}

.prompt-list.two-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.column {
  width: 48%;
}

.prompt-list h3 {
  color: #276D8B;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-bottom: 2px solid #276D8B;
  padding-bottom: 5px;
}

.prompt-list ul {
  list-style-type: none;
  padding: 0;
}

.prompt-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
}

.prompt-item.has-prompt {
  background-color: #e8f5e9;
}

.prompt-item.no-prompt {
  background-color: #ffebee;
}

.prompt-name {
  font-weight: 600;
}

.prompt-status {
  font-weight: 500;
}

.has-prompt .prompt-status {
  color: #4caf50;
}

.no-prompt .prompt-status {
  color: #f44336;
}

.question-item {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.question-text {
  font-weight: 600;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .prompt-list-container {
    padding: 10px;
  }

  .header-row {
    flex-direction: column;
  }

  .header-right {
    margin-left: 0;
    margin-top: 20px;
  }

  .prompt-list-title {
    font-size: 20px;
  }

  .firm-select-container select {
    width: 100%;
    margin-top: 10px;
  }

  .column {
    width: 100%;
  }
}